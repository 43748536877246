<template>
  <v-row justify="center" class="text-center pa-lg-5">
    <v-col cols="12">
      <UserForm type="create" @dismiss="onDone" @updated="onDone" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "UserCreate",
  metaInfo: { title: "Create User" },
  components: {
    UserForm: () => import("@/views/components/User/Form"),
  },
  methods: {
    onDone() {
      this.$router.push({ name: "user.index" });
    },
  },
};
</script>

<style scoped></style>
